export type UndoSubscriptionCancellationFetcherParams = {
  id: string;
  onError: (error: Error) => void;
};

export enum SubscriptionStatus {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  FAILED = 'failed',
  ON_HOLD = 'on-hold',
  PENDING_CANCEL = 'pending-cancel',
  CF_PENDING_CANCEL = 'cf-pending-cancel',
  CANCELLED = 'cancelled',
}

export enum SubscriptionType {
  ALL_ACCESS = 'All Access',
  GRAPHICS = 'Graphics',
  CRAFTS = 'Crafts',
  FONTS = 'Fonts',
  SPARK = 'CF Spark - Creator',
  CLASSES = 'Classes',
  ALL_ACCESS_MONTHLY = 'All Access - Monthly',
  ALL_ACCESS_YEARLY = 'All Access - Yearly',
  ALL_ACCESS_POD_MONTHLY = 'All Access Full POD - Monthly',
  ALL_ACCESS_POD_YEARLY = 'All Access Full POD - Yearly',
}

type SubscriptionUpgrade = {
  plan: number;
  url: string;
  plusPriceMonth: string;
  plusPriceYear: string | false;
  currency: string;
};

export type Subscription = {
  id: number;
  status: SubscriptionStatus;
  type: SubscriptionType;
  plan: number;
  endDate: string;
  trial: boolean;
  upgrade: SubscriptionUpgrade;
};
