import qs from 'qs';
import * as Sentry from '@sentry/nextjs';

import { fetcher } from 'api/fetcher';
import { ApiEndpoint } from 'api/fetcher.types';
import { MeFetcherParams, MeQueryParams } from 'api/requests/me/me.types';
import { UserData } from 'api/requests/user/user.types';
import { nonceFetcher } from 'api/requests/nonce/nonce';
import { NonceType } from 'api/requests/nonce/nonce.types';

const getMeUrl = (queryParams?: MeQueryParams) => {
  const { nonce } = queryParams || {};
  const query = qs.stringify({
    _wpnonce: nonce,
  });

  return `${ApiEndpoint.ME}${query && '?' + query}`;
};

export const meFetcher = async (params?: MeFetcherParams) => {
  const { cookieString, onError } = params || {};
  const nonceData = await nonceFetcher({ nonceType: NonceType.DEFAULT, cookieString });
  const nonce = nonceData?.nonce;

  if (!nonce) {
    Sentry.captureException(new Error(`Failed to fetch nonce for me request, nonceData: ${nonceData}`));

    return { user: null };
  }

  const fetchData = fetcher<UserData>({
    url: getMeUrl({ nonce }),
    options: { headers: { 'Content-Type': 'application/json' } },
    cookieString,
    onError,
  });

  return fetchData;
};
