import { useCallback } from 'react';
import { useIntl, IntlFormatters, FormatNumberOptions } from 'react-intl';

import { useCurrentCurrency } from 'hooks/useCurrency/useCurrency';
import type en from 'translations/en.json';

export type Translations = keyof typeof en;

type FormatMessageParameters = Parameters<IntlFormatters['formatMessage']>;

export type FormatMessageOptions = {
  // since we pass in `id` as the first parameter,
  // we need to omit it from the options
  descriptor?: Omit<FormatMessageParameters[0], 'id'>;
  values?: FormatMessageParameters[1];
  opts?: FormatMessageParameters[2];
};

export type T<Id extends string> = (id: Id, options?: FormatMessageOptions) => string;

export type TranslatorParameters = Parameters<T<Translations>>;

export function useFormatMessage<Ids extends string = Translations>(): T<Ids> {
  const { formatMessage } = useIntl();

  return (id, options = {}) => {
    return formatMessage({ id, ...options?.descriptor }, options?.values, options?.opts);
  };
}

export function useFormatDate() {
  return useIntl().formatDate;
}

export function useFormatCurrency() {
  const { formatNumber } = useIntl();
  const currency = useCurrentCurrency();

  return useCallback(
    (value: number | bigint, opts?: FormatNumberOptions | undefined) =>
      formatNumber(value, { style: 'currency', currency, ...opts }),
    [currency, formatNumber],
  );
}

export * from 'react-intl';
