import { useRouter } from 'next/router';
import useCookies from 'react-cookie/cjs/useCookies';
import { DEFAULT_LOCALE } from 'translations/locales';
import { ContentLanguage } from 'cf-web/packages/codegen/types';

import { useOptionalUser } from 'hooks/useAuthState/useAuthState';
import { AlgoliaLanguage } from 'components/elements/organisms/algoliaConnected/SearchResults/SearchResults.types';
import { getCookie } from 'utilities/getCookie';

export type Locale = 'en' | 'de' | 'es' | 'fr' | 'it' | 'nl' | 'pt' | 'pl';

export const localeCookie = process.env.NEXT_PUBLIC_LOCALE_COOKIE_NAME as string;

export const mapLocaleToContentLanguage = new Map<Locale, AlgoliaLanguage>([
  ['en', AlgoliaLanguage.EN],
  ['es', AlgoliaLanguage.ES],
  ['de', AlgoliaLanguage.DE],
  ['fr', AlgoliaLanguage.FR],
  ['it', AlgoliaLanguage.IT],
  ['nl', AlgoliaLanguage.NL],
  ['pt', AlgoliaLanguage.PT],
  ['pl', AlgoliaLanguage.PL],
]);

export const DEFAULT_ALGOLIA_LANGUAGE = AlgoliaLanguage.EN;

export const mapLocaleToContentLanguageType = new Map<string, ContentLanguage>([
  ['en', ContentLanguage.LanguageEn],
  ['es', ContentLanguage.LanguageEs],
  ['de', ContentLanguage.LanguageDe],
  ['fr', ContentLanguage.LanguageFr],
  ['it', ContentLanguage.LanguageIt],
  ['nl', ContentLanguage.LanguageNl],
  ['pt', ContentLanguage.LanguagePtBr],
  ['pl', ContentLanguage.LanguagePl],
]);

export const mapContentLanguageToLocale = new Map<ContentLanguage, string>([
  [ContentLanguage.LanguageEn, 'en'],
  [ContentLanguage.LanguageEs, 'es'],
  [ContentLanguage.LanguageDe, 'de'],
  [ContentLanguage.LanguageFr, 'fr'],
  [ContentLanguage.LanguageIt, 'it'],
  [ContentLanguage.LanguageNl, 'nl'],
  [ContentLanguage.LanguagePtBr, 'pt'],
  [ContentLanguage.LanguagePl, 'pl'],
]);

export const DEFAULT_ALGOLIA_CONTENT_LANGUAGE = ContentLanguage.LanguageEn;

export function getLocale() {
  const locale = (getCookie(localeCookie) || DEFAULT_LOCALE) as Locale;
  const algoliaLang = mapLocaleToContentLanguage.get(locale) || DEFAULT_ALGOLIA_LANGUAGE;
  const contentLanguage = mapLocaleToContentLanguageType.get(locale) || DEFAULT_ALGOLIA_CONTENT_LANGUAGE;

  return { locale, algoliaLang, contentLanguage };
}

export function useLocale() {
  const router = useRouter();
  const [cookie] = useCookies([localeCookie]);
  const user = useOptionalUser();

  //If user logged in then we use the user's locale
  //If user logged out we use the router locale
  //If no router locale use the lang cookie
  //If no cookie then we use the default locale
  const locale = (router?.locale ||
    cookie[localeCookie] ||
    user?.localization.languageCode ||
    DEFAULT_LOCALE) as Locale;
  const algoliaLang = mapLocaleToContentLanguage.get(locale) || DEFAULT_ALGOLIA_LANGUAGE;
  const contentLanguage = mapLocaleToContentLanguageType.get(locale) || DEFAULT_ALGOLIA_CONTENT_LANGUAGE;

  return { locale, algoliaLang, contentLanguage };
}
