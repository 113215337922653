import { useMemo } from 'react';

import { SubscriptionStatus, SubscriptionType } from 'api/requests/subscriptions/subscriptions.types';
import { useOptionalUser } from 'hooks/useAuthState/useAuthState';

export const accessSubscriptionStatuses = new Set([
  SubscriptionStatus.ACTIVE,
  SubscriptionStatus.PENDING_CANCEL,
  SubscriptionStatus.CF_PENDING_CANCEL,
]);

export function useHasSubscription(type: SubscriptionType) {
  const user = useOptionalUser();

  return useMemo(() => {
    return Boolean(
      user?.subscriptions?.find(sub => {
        return accessSubscriptionStatuses.has(sub.status) && sub.type === type;
      }),
    );
  }, [user?.subscriptions, type]);
}

export function useHasSparkAccess() {
  const user = useOptionalUser();

  return useMemo(() => {
    return (
      Boolean(user?.settings.hasAllAccess) ||
      Boolean(
        user?.subscriptions?.find(sub => {
          return accessSubscriptionStatuses.has(sub.status) && sub.type === SubscriptionType.SPARK;
        }),
      )
    );
  }, [user?.settings.hasAllAccess, user?.subscriptions]);
}

export function useHasTrialSubscription(type: SubscriptionType) {
  const user = useOptionalUser();

  return useMemo(() => {
    return Boolean(
      user?.subscriptions?.find(sub => {
        return accessSubscriptionStatuses.has(sub.status) && sub.type === type && Boolean(sub?.trial);
      }),
    );
  }, [user?.subscriptions, type]);
}

export function useActiveSubscriptionTypes(): string[] {
  const user = useOptionalUser();

  return useMemo(() => {
    return user?.subscriptions?.filter(sub => accessSubscriptionStatuses.has(sub.status)).map(sub => sub.type) || [];
  }, [user?.subscriptions]);
}
