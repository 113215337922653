export enum AlgoliaLanguage {
  EN = 'LANGUAGE_EN',
  FR = 'LANGUAGE_FR',
  DE = 'LANGUAGE_DE',
  ES = 'LANGUAGE_ES',
  IT = 'LANGUAGE_IT',
  PT = 'LANGUAGE_PT_BR',
  NL = 'LANGUAGE_NL',
  PL = 'LANGUAGE_PL',
}

export type AlgoliaTranslatedString = Record<AlgoliaLanguage, string>;

export type AlgoliaContentHit = {
  __position: number;
  __queryID: string;
  _highlightResult?: {
    title: Record<AlgoliaLanguage, { value: string }>;
  };
  authorId: string;
  objectID: string;
  slug: AlgoliaTranslatedString;
  thumbnail: { url: string; width: number; height: number };
  previewMobile: { url: string; width: number; height: number };
  previewDesktop: { url: string; width: number; height: number };
  title: AlgoliaTranslatedString;
};

export type AlgoliaSearchedProductsLocalStorage = {
  items: Record<string, Record<string, string>>;
  expiresAt: Date;
};
