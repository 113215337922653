import React from 'react';
import classNames from 'classnames';

import { DividerProps } from './Divider.types';

export const Divider = ({ thick, className, children, orientation = 'horizontal' }: DividerProps) => {
  const classes: string = classNames(
    'm-0 border-0 border-solid border-[#DFDDE5]',
    thick
      ? orientation === 'horizontal'
        ? `border-t-[medium]`
        : 'border-r-[medium]'
      : orientation === 'horizontal'
      ? `border-t-[thin]`
      : 'border-r-[thin]',
    orientation === 'vertical' && 'h-full',
    className,
  );

  if (children) {
    return <div className={classes}>{children}</div>;
  }

  return <hr className={classes} />;
};
