import { useContext } from 'react';

import { CurrencyStateContext } from 'components/contexts/currenciesProvider/CurrencyProvider';
import { Currency } from 'api/requests/site/site.types';

export const useCurrency = () => {
  const context = useContext(CurrencyStateContext);

  if (context === null) {
    throw new Error('useCurrency should be used inside a component wrapped in a Currency Provider');
  }

  return context;
};

export function useCurrentCurrency() {
  const { activeCurrency } = useCurrency();

  return activeCurrency.code || Currency.DOLLAR;
}
